<template>
  <div class="mt-4">
    <v-app-bar app dense color="green" dark class="overflow-visible">
      <v-app-bar-nav-icon @click="requestDrawer"></v-app-bar-nav-icon>
      <v-app-bar-title class="pl-2">
        <v-chip small
                label
                @click="deselectTag(tag)"
                v-for="tag in selectedTags"
                :key="tag.id"
                :color="`${tag.color} lighten-2`"
                dark
                class="ml-2"
        >
          {{tag.text}}
          <v-icon x-small class="ml-2">$delete</v-icon>
        </v-chip>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <new-item-icon @addTaskRequested="addTaskRequested"
                     @addEventRequested="addEventRequested"
                     @addContactRequested="addContactRequested"
                     @addFileRequested="addFileRequested"></new-item-icon>
    </v-app-bar>
    <v-slide-y-transition group tag="v-list">
      <contact-list-item :contact="contact" @click.native="viewContact(contact)" v-for="contact in filteredContacts" :key="contact.id" />
    </v-slide-y-transition>
    <contact-details :contact="selectedContact" :trigger.sync="detailsOpen" @deleteRequested="deleteContact" @editRequested="editContact" />
  </div>
</template>

<script>
import authenticator from '@/firebase/authenticator'
import { db } from '@/firebase/firestore'
import ContactListItem from '@/components/listItems/ContactListItem'
import contactApi from '@/firebase/contactApi'
import NewItemIcon from '@/components/icons/NewItemIcon'
import ContactDetails from '@/components/details/ContactDetails'
import TagCoordinator from '@/components/services/TagCoordinator'

export default {
  name: 'Contacts',
  components: { ContactListItem, ContactDetails, NewItemIcon },
  created () {
    authenticator.getCurrentUser().then((user) => {
      this.$bind('contacts', db.collection(`users/${user.id}/contacts`).orderBy('firstName'))
      this.user = user
    })
  },
  data: () => ({
    contacts: [],
    selectedContact: { },
    detailsOpen: false,
    searchText: '',
    selectedTags: TagCoordinator.selectedTags
  }),
  computed: {
    filteredContacts () {
      return this.filterContacts(this.selectedTags)
    }
  },
  methods: {
    deselectTag (tag) {
      TagCoordinator.deselectTag(tag)
    },
    filterContacts () {
      return this.contacts.filter(c => TagCoordinator.itemTagSelected(c))
    },
    viewContact (contact) {
      this.selectedContact = contact
      this.detailsOpen = true
    },
    editContact (contact) {
      this.$emit('editContact', contact)
    },
    async deleteContact (contact) {
      await contactApi.deleteContact(contact)
    },
    addTaskRequested () {
      this.$emit('addTaskRequested')
    },
    addEventRequested () {
      this.$emit('addEventRequested')
    },
    addContactRequested () {
      this.$emit('addContactRequested')
    },
    addFileRequested () {
      this.$emit('addFileRequested')
    },
    requestDrawer () {
      this.$emit('showDrawer')
    }
  }
}
</script>

<style scoped>

</style>
