<template>
  <v-bottom-sheet max-width="600" v-model="showing">
    <v-card tile>
      <v-card-title dark>
        {{contact.firstName}} {{contact.lastName}}
      </v-card-title>
      <v-card-subtitle class="mb-0 pb-0">
        {{orgAndRole}}
      </v-card-subtitle>
      <v-card-subtitle></v-card-subtitle>
      <v-card-text>
        <v-list dense class="pa-0">
          <v-list-item dense class="px-0">
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{email || 'No Email'}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense class="px-0">
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-cellphone</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{formattedPhone}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense class="px-0">
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-map-marker</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{contact.address || 'No Address'}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-container class="pa-0 mt-4 d-inline-block">
          {{contact.notes || 'No Notes'}}
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="primary" @click="requestEdit(contact)">Edit</v-btn>
        <v-spacer></v-spacer>
        <v-dialog max-width="400" v-model="confirming">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" outlined color="error">Delete</v-btn>
          </template>
          <v-card>
            <v-toolbar dark color="green">
              Delete Contact?
              <v-spacer></v-spacer>
              <v-icon>mdi-alert</v-icon>
            </v-toolbar>
            <v-card-text class="pt-5">
              Are you sure you want to delete this contact?
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="error" dark @click="requestDelete(contact); confirming = false">Yes</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="confirming = false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'ContactDetails',
  props: {
    trigger: Boolean,
    contact: Object
  },
  data: () => ({
    showing: false,
    searchText: '',
    confirming: false
  }),
  methods: {
    requestDelete (contact) {
      this.$emit('deleteRequested', contact)
      this.showing = false
    },
    requestEdit (contact) {
      this.$emit('editRequested', contact)
      this.showing = false
    }
  },
  computed: {
    formattedPhone () {
      if (this.contact.phone) {
        if (this.contact.phone.length === 10) {
          return `(${this.contact.phone.substring(0, 3)}) ${this.contact.phone.substring(3, 6)}-${this.contact.phone.substring(6, 10)}`
        } else if (this.contact.phone.length === 7) {
          return `${this.contact.phone.substring(0, 3)}-${this.contact.phone.substring(3, 7)}`
        } else {
          return this.contact.phone
        }
      } else {
        return 'No Phone Info'
      }
    },
    orgAndRole () {
      let orgAndRole = ''
      if (this.contact.role) {
        orgAndRole = this.contact.role
      }
      if (this.contact.organization) {
        orgAndRole += `${this.contact.role ? ' @ ' : ''}${this.contact.organization}`
      }
      return orgAndRole
    }
  },
  watch: {
    trigger (on) {
      this.showing = on
    },
    showing (val) {
      this.$emit('update:trigger', val)
    }
  }
}
</script>

<style scoped>

</style>
