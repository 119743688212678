<template>
  <v-list-item>
    <v-list-item-avatar color="grey" style="color: white;" size="35">
      {{firstInitial}}{{lastInitial}}
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{primaryNamePart}} {{secondaryNamePart}}
      </v-list-item-title>
      <v-list-item-subtitle v-if="orgAndRole">
        {{orgAndRole}}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'ContactListItem',
  props: {
    contact: Object
  },
  data: () => ({
    lastFirst: false
  }),
  computed: {
    firstInitial () {
      if (this.contact.firstName) {
        return this.contact.firstName.substring(0, 1)
      } else {
        return ''
      }
    },
    lastInitial () {
      if (this.contact.lastName) {
        return this.contact.lastName.substring(0, 1)
      } else {
        return ''
      }
    },
    orgAndRole () {
      let orgAndRole = ''
      if (this.contact.role) {
        orgAndRole = this.contact.role
      }
      if (this.contact.organization) {
        orgAndRole += `${this.contact.role ? ' @ ' : ''}${this.contact.organization}`
      }
      return orgAndRole
    },
    primaryNamePart () {
      if (this.lastFirst) return `${this.contact.lastName},`
      else return this.contact.firstName
    },
    secondaryNamePart () {
      if (this.lastFirst) return `${this.contact.firstName}`
      else return this.contact.lastName
    }
  }
}
</script>

<style scoped>

</style>
